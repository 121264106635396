import { Ambiente } from '../../types';
import { ElegibilidadeDashboardDisciplinaConfig, Params } from './elegibilidade-dashboard-disciplina.types';

const configDefault: ElegibilidadeDashboardDisciplinaConfig = {
  periodoMinimo: '2025.1',
  blocklist: [
    {
      marca: 'idomed',
    },
    {
      marca: 'estacio',
      tipoCurso: 'ESPECIALIZAÇÃO',
    },
  ],
};

const configsPorAmbiente: Record<Ambiente, ElegibilidadeDashboardDisciplinaConfig> = {
  dev: {
    periodoMinimo: '2024.2',
    blocklist: configDefault.blocklist,
  },
  prd: configDefault,
  hml: configDefault,
  local: {
    periodoMinimo: '2024.2',
    blocklist: configDefault.blocklist,
  },
};

export const getElegibilidadeDashboardDisicplinaConfig = ({
  ambiente,
}: Params): ElegibilidadeDashboardDisciplinaConfig => configsPorAmbiente[ambiente];
