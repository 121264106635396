import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getChatScriptSource } from '../commons/assistente-pessoal/assistente-pessoal.config';
import { getAvaliacoesConfig } from '../commons/avaliacoes/avaliacoes.config';
import { detectaNavegador } from '../commons/detecta-navegador/detecta-navegador.config';
import { getElegibilidadeDashboardDisicplinaConfig } from '../commons/elegibilidade-dashboard-disciplina/elegibilidade-dashboard-disciplina.config';
import { getFormacaoSociomeocionalConfig } from '../commons/formacao-socioemocional/formacao-socioemocional.config';
import { getChatHelp } from '../commons/menu/chat-help/chat-help.config';
import { getSubMenusDisciplinas } from '../commons/menu/disciplinas/disciplinas.config';
import { getItensMenuConfig } from '../commons/menu/menu.config';
import { paraMsalConfiguration } from '../commons/msal.commons';
import { getTapumeAvaliacoesConfig } from '../commons/tapume-avaliacoes/tapume-avaliacoes.config';
import { getTapumeConfig } from '../commons/tapume-erro-carregamento/tapume-erro-carregamento.config';
import { Environment } from '../types';
import {
  AJUDA_LOGIN,
  EMPREGABILIDADE,
  EXTENSOES_VALIDAS_ENVIO_ARQUIVO,
  tapume,
  TESTE_SOCIOCOMPORTAMENTAL,
} from './common';

const buildSettings = (): UserManagerSettings => ({
  authority: 'https://login.microsoftonline.com/da49a844-e2e3-40af-86a6-c3819d704f49/v2.0/',
  client_id: '806f7ced-6e0c-4c96-a389-e95ae18fabbb',
  redirect_uri: `${window.location.protocol}//${window.location.host}`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
  scope: '806f7ced-6e0c-4c96-a389-e95ae18fabbb/.default',
  userStore: new WebStorageStateStore({ store: localStorage }),
  prompt: 'select_account',
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: false,
});

const dev = (): Environment => {
  const settings: UserManagerSettings = buildSettings();
  const apiUrl = localStorage.getItem('apiUrl');
  const apiV2Url = localStorage.getItem('apiV2Url');
  const urlQc = localStorage.getItem('urlQc');
  const ambiente = 'dev';
  const marca = 'idomed';
  return {
    contextos: [{ host: 'https://estudante-sava-idomed.estaciodev.net' }],
    ambiente,
    marca,
    tapume,
    idClienteEnsineme: 'A39EAE23-8E99-27B8-82FC-D5ABAF8953CD',
    API_DEDICADA: apiUrl ?? 'https://apis.estudante.idomed.sava.dev.yduqs.com.br/rest',
    API: apiUrl ?? 'https://sava-idomed.estaciodev.net/apis',
    API_V2: apiV2Url ?? 'https://estudante-sava-idomed.estaciodev.net/aura-frontend',
    URL_QC: urlQc ?? 'https://lms.stg.labs.qcx.com.br',
    URL_REDIRECT_ENSINEME: 'https://stecine.azureedge.net/repositorio/redirect/_redirect.html',
    URL_REDIRECT_REDEFINIR_SENHA: 'https://account.activedirectory.windowsazure.com/ChangePassword.aspx',
    URL_ESQUECEU_SENHA: 'https://passwordreset.microsoftonline.com/',
    URL_EDUCADOR: 'https://educador-sava-idomed.estaciodev.net',
    URL_FAQ: '/aura-repo/estacio/faq-estudante.pdf',
    URL_MANUAL: '/aura-repo/estacio/manual-estudante.pdf',
    URL_BASE_CALENDARIO_ACADEMICO: '/aura-repo/calendario-academico/',
    CHAT_HELP: getChatHelp('idomed'),
    EVENT_LOGIN_ENDPOINT: '/v2/eventos-login/alunos',
    EXTENSOES_VALIDAS_ENVIO_ARQUIVO,
    userManagerSettings: settings,
    msalConfiguration: paraMsalConfiguration({ settings }),
    GTM_ID: 'GTM-56HT2PR',
    TAMANHO_PAGINA_RESPOSTA_TRABALHOS: 1,
    EMPREGABILIDADE,
    AJUDA_LOGIN,
    ITENS_MENU: getItensMenuConfig({ ambiente, marca }),
    ITENS_AVALIACOES: getAvaliacoesConfig({ ambiente, marca }),
    ID_INSTITUICAO_LINKEDIN: '74530404',
    URL_SCRIPT_CHAT_ASSISTENTE_PESSOAL: getChatScriptSource(ambiente),
    URL_ACESSO_SIRIUS_EXERCICIOS: 'https://idomed-dev.saladeavaliacoes.com.br',
    URL_ACESSO_SIRIUS_SIMULADOS: 'https://idomed-dev.saladeavaliacoes.com.br',
    URL_FICHA_CATALOGRAFICA: `https://ltechnology.com.br/gleison/`,
    SUBMENUS_DISCIPLINAS: getSubMenusDisciplinas(),
    FORMACAO_SOCIOEMOCIONAL: getFormacaoSociomeocionalConfig(),
    TAPUME_AVALIACOES: getTapumeAvaliacoesConfig(),
    TAPUME_CONFIG: getTapumeConfig({ marca }),
    NAVEGADOR_CONFIG: detectaNavegador(),
    TESTE_SOCIOCOMPORTAMENTAL,
    ELEGIBILIDADE_DASHBOARD_DISCIPLINA: getElegibilidadeDashboardDisicplinaConfig({ ambiente }),
  };
};

export default dev;
